import Controller from './ControlDescriptor.vue'
import SimpleInput from './SimpleInput.vue'
import InputLabel from './InputLabel.vue'
import InputTextArea from './TextArea.vue'
import MultiselectInput from './MultiselectInput.vue'

export default function install(Vue, { prefix = 'DControl' } = {}) {
  Vue
    .component(`${prefix}Input`, Controller)
    .component(`${prefix}SimpleInput`, SimpleInput)
    .component(`${prefix}Label`, InputLabel)
    .component(`${prefix}Textarea`, InputTextArea)
    .component(`${prefix}Multiselect`, MultiselectInput)
}