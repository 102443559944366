import './public-path'

import { createApp } from 'vue'

import { createApplication } from '@/app'
import './styles/index.sass'

const app = createApplication({
  createApp,
})

app.mount('#app')