import { prefixAPI } from '@resource/api';

import {
  createReceiver,
  createSender,
  createCreateWithFile,
} from '@resource/resource';

const LOGIN = '/api/v1/auth/login/'
const LOGOUT = '/api/v1/auth/logout/'

export const loginResource = createSender(LOGIN)
export const logoutReceiver = createSender(LOGOUT)


