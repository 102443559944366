import { Form, Field, ErrorMessage, defineRule, configure } from 'vee-validate/dist/vee-validate.js'

configure({
  bails: false,
  validateOnBlur: false,
  validateOnChange: false,
  validateOnInput: false,
  validateOnModelUpdate: false,
});

export {
  Form, Field, ErrorMessage, defineRule, configure,
}
