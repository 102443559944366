import { prefixAPI } from '@resource/api';

import {
  createReceiver,
  createSender,
  createCreateWithFile,
} from '@resource/resource';

const MODEL = 'crm'

const BAN_MESSAGES = prefixAPI('ban-messages/', MODEL)
const CALL_BACK = prefixAPI('set-call-back/')
const COMMENT_LIST = prefixAPI('comment-list/{id}/')
const COMMENT_CREATE = prefixAPI('comment-create/')
const CONSUMER_LIST = prefixAPI('consumer-list/{?limit,offset}')
const CONSUMER = prefixAPI('consumer/{id}/')
const SMS_TEMPLATE_LIST = prefixAPI('manager-sms-template-list/')
const SEND_SMS = prefixAPI('manager-sms-send/')
const SCRIPT_LIST = prefixAPI('manager-script-list/')
const SET_SUCCESSFUL_LIST = prefixAPI('set-successful/')

export const banMessageResource = createSender(BAN_MESSAGES)
export const callBackResource = createSender(CALL_BACK)
export const commentsListResource = createReceiver(COMMENT_LIST)
export const commentsCreateResource = createSender(COMMENT_CREATE)
export const consumerListResource = createReceiver(CONSUMER_LIST)
export const consumerResource = createReceiver(CONSUMER)
export const smsTemplateListResource = createReceiver(SMS_TEMPLATE_LIST)
export const sendSmsResource = createSender(SEND_SMS)
export const scriptsListResource = createReceiver(SCRIPT_LIST)
export const setSuccessfulResource = createSender(SET_SUCCESSFUL_LIST)









