import Controls from './Controls'
import UiElements from './UiElements'
import ConsumerList from './ConsumerList.vue'
import ConsumerInfo from './ConsumerInfo.vue'
import ModalComponents from './Modal'
import SendSmsForm from './SendSmsForm'
import ScriptBlock from './ScriptBlock'
import CommentBlock from './CommentBlock'
import SendMessageConfiguration from './SendMessageConfiguration'
import CallBackBlock from './CallBackBlock'
import LoginForm from './LoginForm'
import LogoutForm from './LogoutForm'

export default function install(Vue = {}) {
  Vue.use(Controls)
  Vue.use(UiElements)
  Vue.use(ModalComponents)
  Vue.component('ConsumerList', ConsumerList)
  Vue.component('SendSmsForm', SendSmsForm)
  Vue.component('ConsumerInfo', ConsumerInfo)
  Vue.component('ScriptBlock', ScriptBlock)
  Vue.component('CommentBlock', CommentBlock)
  Vue.component('SendMessageConfiguration', SendMessageConfiguration)
  Vue.component('CallBackBlock', CallBackBlock)
  Vue.component('LoginForm', LoginForm)
  Vue.component('LogoutForm', LogoutForm)
}
