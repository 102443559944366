// Base elements
import UiLoader from './UiLoader.vue'
import UiConfirm from './UiConfirm'
import LimitPagination from './LimitPagination'


export default function install(Vue) {
  Vue.component('UiLoader', UiLoader)
  Vue.component('UiConfirm', UiConfirm)
  Vue.component('LimitPagination', LimitPagination)
}
