import i18n from './i18n'

import installApp from '@/install'
import Components from './components'
import Plugins from '@/plugins'
import { install } from "vue3-recaptcha-v2";

export function createApplication({
  createApp,
}) {
  const app = createApp({
    data() {
      return {
        True: true,
        False: false,
        None: undefined,
      }
    },
  })

  app.config.globalProperties.$log = console.log // you can use $log in template
  app.config.compilerOptions.delimiters = ['[[', ']]'] // use this delimiters in pug(jinja)-templates

  app
    .use(i18n)
    .use(installApp)
    .use(Plugins)
    .use(Components)
    .use(install, {
      sitekey: window.site_key,
      cnDomains: false,
    })

  return app
}
